.hero {
    height: 100%;
}

#background-video{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -5;
}

.hero_bg {    
    /* position: absolute;
    z-index: 1; */
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      /* width: 100%; */
      height: calc(100vh - 66px);
      color: var(--color-tx);
      position: relative;
      z-index: -2;
}

.hero_overlay {
    background-color: rgba(0,0,0, 0.6);
    height: 100%;
    width: 100%;

}
.hero_heading {
    /* background-color: rgba(0,0,0, 0.7); */
    width: fit-content;
    padding: 1em;

    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-25%, -50%);
    
}
.hero_heading h1 {
    /* font-family: var(--font-titles); */
    font-weight: 700;
}
.hero_heading p {
    /* font-family: var(--font-titles); */
    font-style: italic;
    margin-top: 2em;
}
