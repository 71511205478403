@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200;1,400&display=swap');

:root {
    --font-title: 'Poppins', sans-serif;
    --font-cont: 'Source Sans Pro', sans-serif;
    --color-bg: #000;
    /* --color-bg-sec: #faf9f9; */
    --color-bg-sec: #f8f9fa;
    --color: #fff;
    --color-sec: #000;
    --color-blc-50: rgba(0, 0, 0, 0.5);
    --color-accent: #0f0;
    --color-accent-50: rgba(0, 255, 0, 0.5);
}

* {
    letter-spacing: 1px;
}