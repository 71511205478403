header {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: sticky;
    top: 0;
    z-index: 6;
}

.logo {
    height: 48px;
    z-index: 6;
}