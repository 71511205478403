.modal-bg {
    position: fixed;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal {
    color: #000;
    position: fixed;
    background-color: #fff;
    z-index: 8;
    top: 4.5em;
    left: 50%;
    transform: translate(-50%, 0);
    border-color: #000;
    border: solid 0.2px;
    -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}

.modal>* {
    padding: 1em;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
}

.modal-body {
    border-top: solid 0.5px;
    border-bottom: solid 0.5px;
}

.modal-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.modal-success .modal-body {
    border-color: #c3e6cb;
}

.modal-error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.modal-error .modal-body {
    border-color: #f5c6cb;
}

.modal-title {
    font-family: var(--font-title);
    font-size: larger;
}

.modal-footer {
    text-align: right;
}

@media screen and (max-width: 1150px) {
    .modal {
        width: 80%;
    }
}