.navbar {
    color: var(--color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    margin-right: -1em;
}

.navbar a,
.navbar-smallscreen a {
    color: var(--color);
    font-family: var(--font-title);
    font-weight: 300;
    text-decoration: none;
    padding: 1em;
    cursor: pointer;
}

.navbar a.active,
.navbar-smallscreen a.active {
    color: var(--color-accent);
}

.navbar a:hover,
.navbar a:focus,
.navbar-smallscreen a:hover,
.navbar-smallscreen a:focus {
    color: var(--color-accent-50);
    text-shadow: -1px 0 1px #fff;
}

.navbar-smallscreen {
    display: none;
    transition: all 1s ease;
}

.overlay__open:hover {
    cursor: pointer;
}

.navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    clip-path: circle(10vmax at 96% 0%);
    -webkit-clip-path: circle(10vmax at 96% 0%);
    background: var(--color-bg);
    transition: all 1s ease;
    flex-direction: column;
    z-index: 5;
    overflow-y: auto;
}

.navbar-smallscreen_overlay.open {
    clip-path: circle(120vmax at 96% 0%);
    -webkit-clip-path: circle(120vmax at 96% 0%);
    transition: all 1s ease-out;
}


.navbar-smallscreen_overlay .overlay__close {
    cursor: pointer;
    position: fixed;
    top: 0.5rem;
    right: 1rem;
}


.navbar-smallscreen_links {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
}

.navbar-smallscreen_links a {
    padding: 0.8em;
    width: 100%;
}

@media screen and (max-width: 1150px) {
    .navbar {
        display: none;
    }

    .navbar-smallscreen {
        display: flex;
    }
}