.services {
    background-color: var(--color-bg-sec);
    color: var(--color-sec);
    /* padding: 1em; */
}

.srv-hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
}

.srv-hero h6 {
    font-size: 1em;
    font-family: var(--font-title);
}

.srv-hero img {
    height: 15em;
}

.srv-hero div {
    display: flex;
    flex-direction: row;
    padding-left: 1em;
}

.srv-hero ul.check-list {
    list-style-type: none;
    padding-left: 1em;
}

.service-list {
    padding: 1.1em;
    display: flex;
    flex-wrap: wrap;
    /* column-gap: 1.5em; */
    /* row-gap: 2em; */
    /* justify-content: center; */
}

/* .service-list:after {
    content: "";
    flex: 99999 1 auto;
} */

.service-item {
    flex: calc(100% / 3);
    max-width: calc(100% / 3);
}

.service-card {
    border: solid 1px #e6e6e6;
    border-radius: 5px;
    display: flex;
    /* flex: calc(100% / 3 - 3em);
    max-width: calc(100% / 3 - 3em); */
    flex-direction: column;
    margin: 0.9em;

}

.service-card:hover {
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
    border-color: transparent;
    cursor: pointer;
}

.service-card div._img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    width: 100%;
    padding-top: 75%;
    background-color: #E5E7E8;
}


.service-card img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/* a .service-card h6,
a .service-card p {
    color: #000;
    text-decoration: none;
} */

.service-card h6 {
    font-size: 1em;
    padding: 1em 0.5em 0.2em;
}

.service-card p {
    padding: 0.2em 0.5em;
    text-align: justify;
}

.service-card a {
    padding: 1em 0.5em;
}

.service-card p::after {
    content: "..."
}

.service h3 {
    display: inline-flex;
    align-items: center;
}

.service .go-back {
    padding-right: 1em;
    display: flex;
    align-items: center;
    color: #00f;
    cursor: pointer;
}

.service .go-back i {
    padding-left: 0.6em;
}

.return-icon {
    font-size: 1.5em;
}

.service h3 span {
    border-left: 3px solid #000;

    padding-left: 1em;
}

.service p {
    margin-top: 1em;
    margin-bottom: 1em;
}

.service p.ul {
    margin-bottom: 0.3em;
}

.service ul {
    margin-left: 1em;
    margin-bottom: 1em;
}

.service img {
    float: right;
    height: 250px;
    width: 350px;
}

@media screen and (max-width: 1150px) {
    .srv-hero {
        padding-right: unset;
        flex-direction: column;
    }

    .srv-hero img {
        height: 20vh;
        width: 100vw;
    }

    .srv-hero div {
        flex-direction: row;
        padding: 1em;
        align-self: start;
        width: 100%;
    }

    .srv-hero ul.check-list {
        list-style-type: none;
        padding-left: unset;
        padding-top: 0.2em;
        padding-left: 0.5em;
        align-self: end;
    }

    .service-list {
        padding: 1em 0;
    }

    .service-item {
        flex: 100%;
        max-width: unset;
    }

    .service-card {
        /* flex: 100%; */
        /* max-width: unset; */
        margin: 0.5em 1em;
    }

    .service-card div._img {
        padding-top: 66%;
        height: calc(100vw * 0.6);
    }

    .service-card img {
        height: calc(100vw * 0.6);
    }

    .service img {
        height: 34vw;
        width: 40vw;
    }
}