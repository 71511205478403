* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
    color: var(--color);
    font-family: var(--font-cont);
    font-weight: 400;
}

.container {
    padding: 0.5em 2em;
}

@media screen and (max-width: 1150px) {
    .container {
        padding: 0.5em 1em;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-title);
    font-weight: 700;
}

.page-head {
    text-align: center;
    margin-bottom: 2em;
    padding-top: 2em;
    color: #00f;
}