.not-found {
    color: var(--color-sec);
    background-color: var(--color-bg-sec);
    text-align: center;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.not-found * {
    margin: 2em auto;
}

.not-found_header {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.not-found_header svg {
    align-self: center;
    height: 3em;
    width: 3em;
    fill: orange
}

button {
    padding: 1em;
    width: fit-content;
    border: none;
    border-radius: 5px;
    align-self: center;
    color: var(--color-accent);
    background-color: var(--color-bg);
}

button:hover,
button:focus {
    color: var(--color-accent-50);
    background-color: var(--color-blc-50);
    cursor: pointer;
}