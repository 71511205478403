.contact {
    background-color: var(--color-bg-sec);
    color: var(--color-sec);
    padding: 0 1em 1em 1em;
}

.contact form {
    display: flex;
    flex-wrap: wrap;
}

form .form-group:first-child {
    margin-top: 1.5em;
}

.form-group {
    flex: 50%;
}

.form-group.flx-full {
    flex: 100%;
}

.form-group.pl {
    padding-left: 0.5em;
}

.form-group.pr {
    padding-right: 0.5em;
}

.contact .row {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.contact .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
    padding: 1em;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.1);
}

.contact .col-4 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 2em;
}


:focus {
    outline: none;
}

.form-group {
    position: relative;
    margin-bottom: 3em;
}

input,
textarea {
    color: #333;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 1;
    font-family: var(--font-cont);
    font-size: 1.2em;
}

[type='text'],
[type='email'],
textarea,
.is-invalid-input {
    box-shadow: none;
}

[type='text']:focus,
[type='email']:focus,
textarea:focus,
.is-invalid-input:focus {
    border: none;
}


.form-control {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 0;
    background-color: transparent;
}

.form-control~.focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #00f;
    transition: 0.4s;
}

.is-invalid-input:not(:focus) {
    background-color: #f9eceaaa;
}


.form-control-feedback {
    color: #ec5840;
    position: absolute;
    font-size: small;
}

.is-invalid-input~.focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #ec5840;
    transition: 0.4s;
}

.form-control:focus~.focus-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
}

.form-control~.form-label {
    position: absolute;
    width: 100%;
    top: 0;
    color: rgba(0, 0, 0, 0.7);
    transition: 0.3s;
    z-index: 0;
    font-family: var(--font-title);
}

.form-control:focus~.form-label,
.form-control:not(:placeholder-shown)~.form-label {
    top: -1.5em;
    color: #00f;
    transition: 0.3s;
}

.form-label.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.form-label .text-danger {
    color: #ec5840;
}

button:disabled:hover,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.contact-widget h6 {
    margin-top: 1em;
    margin-bottom: 0.25em;
}

.contact-widget h6+* {
    color: var(--color-sec);
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.badge {
    background-color: rgba(204, 204, 204, 1.00);
    width: fit-content;
    padding: 0.5em;
    border-radius: 1em;
}

.contact-widget a {
    color: var(--color-sec);
    text-decoration: none;
}

.contact-widget a:hover,
.contact-widget a:focus {
    color: blue;
}

.contact-widget ul {
    list-style: none;
}

.contact-widget .social-lnks a {
    padding: 0.5em;
    font-size: 1.5em;
}

.contact-widget .social-lnks a:first-child {
    padding-left: 0;
}

/* small device #######################*/
@media screen and (max-width: 1150px) {
    .contact {
        padding: 0;
    }

    .form-group {
        flex: 100%;
    }

    .form-group.pl {
        padding-left: 0;
    }

    .form-group.pr {
        padding-right: 0;
    }

    .contact .row {
        display: flex;
        flex-direction: column;
    }

    .contact .col-8 {
        width: 100%;
    }

    .contact .row {
        gap: unset;
    }

    .contact .col-4 {
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
        align-self: flex-start;
    }

    .contact-widget h6+* {
        /* margin: auto; */
        align-self: center;
        font-size: 1.5em;
        /* text-align: center; */

    }

    .contact-widget .social-lnks {
        display: flex;
        width: 100%;
    }

    .contact-widget .social-lnks a:first-child {
        padding-left: 0.5em;
    }

    .contact-widget .social-lnks a {
        flex-grow: 1;
        padding: 0.5em;
        text-align: center;
    }
}