.about {
    background-color: #f7f9fc;
    color: #000;
}

.about p {
    line-height: 1.6em;
}

.about .img {
    background-color: rgba(0, 0, 255, 0.1);
}

.about .img img {
    width: calc(100vw * 0.38);
    height: calc(100vw * 0.33);
}

.overview {
    display: flex;
    flex-direction: row;
    gap: 2em;
    padding: 2em;
}



.overview .c-name span {
    width: 4em;
    height: 0.5em;
    background-color: var(--color-accent-50);
}

.overview .c-name {
    font-size: 26px;
    position: relative;
    margin-bottom: 40px;
}

h4.c-name:after {
    content: "";
    background: #0410fb;
    color: #0410fb;
    width: 45px;
    height: 5px;
    bottom: -18px;
    position: absolute;
    left: 0;
}

.overview p {
    padding-top: 1em;
    padding-bottom: 1em;
    letter-spacing: 2px;
    text-align: justify;
}

.insights {
    padding: 1em 2em 2em;
    text-align: center;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.insights .table {
    display: flex;
    justify-content: center;
}

.insights .table caption {
    text-align: justify;
}

.insights tr.thd th {
    background-color: rgba(0, 0, 255, 0.7);
    color: #fff !important;
}

.insights th,
.insights td {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1em;
}



.insights td {
    text-align: right;
}

.insights .t-note {
    font-weight: 400;
    font-size: smaller;
}

.coverage {
    display: grid;
    grid-template-columns: 50% 50%;
    /* grid-template-rows: 50vh 50vh; */
    grid-auto-flow: row;
}

.world-img {
    grid-column: 2;
    grid-row: 1 / 3;
}

.world-img img {
    width: 100%;
    height: auto;
}

.coverage p {
    padding: 2em;
}

.coverage div {
    display: flex;
    align-items: center;
}

/* .cvg p {
    padding-top: 1em;
    padding-bottom: 1em;
} */

/* .world-img img {
    max-width: 50vw; */

/* width: calc(100vw * 0.38);
    height: calc(100vw * 0.20); */
/* } */

.bg-dotted {
    height: 5em;
    width: 100%;
    background-image: radial-gradient(#d7d7d7 1px, transparent 1px),
        radial-gradient(#00f 1px, transparent 1px);
    background-position: 0px 0px, 6px 6px;
    background-size: 12px 12px;
}

.vis-mis {
    display: flex;
    flex-direction: row;
    padding: 2em;
    gap: 2em;
}

.about .header-underln {
    font-size: 26px;
    position: relative;
    margin-bottom: 40px;
}

h4.header-underln:after {
    content: "";
    background: #0410fb;
    color: #0410fb;
    width: 45px;
    height: 5px;
    bottom: -18px;
    position: absolute;
    left: 0;
}


.vision,
.mission {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 3em;
}

.service-dev {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

.service-dev-overlay {
    height: 100%;
    width: 100%;
    padding: 2em 2em 8em 2em;
    background-color: rgba(0, 0, 255, 0.3);
    color: #fff;
}

.s-d-cont {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 2em;
}

.s-d-cont ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 1.5em;
    margin-bottom: 1em;
    gap: 1em;
}

.s-d-cont ul li {
    flex-grow: 1;
    padding: 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    /* grdient color */
    /* global 90%+ browsers support */
    background: conic-gradient(from 328deg at 23% 0%, rgba(7, 58, 187, 1) 49%, rgba(0, 255, 13, 1) 100%);

}

.s-d-cont ul li svg {
    height: 2em;
    width: 2em;
    margin-right: 0.4em;
    /* background-color: #fff;
    color: #000; */
}

.capacity {
    padding: 2em;
    background-color: #f7f9fc;
    color: #000;
}

.capacity p:first-child {
    margin: -6em auto 2em;
    padding: 2em;
    border-radius: 5px;
    background-color: #f7f9fc;
    color: #000;
    width: 70%;
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);

}

.partner {
    display: flex;
    flex-direction: row;
}

.partner img {
    width: 50vw;
}

.partner p {
    margin: 2em;
}

@media screen and (max-width: 1150px) {
    .overview {
        flex-direction: column;
        padding: 1em;
    }

    .about .img {
        margin-left: -1em;
        width: 50vw;
        border-top-right-radius: 48%;
        border-bottom-right-radius: 48%;
    }

    .insights {
        padding: 1em 1em 2em;
    }

    .insights .table {
        overflow-x: auto;
        display: flex;
        justify-content: unset;
    }

    .insights th,
    .insights td {
        padding: 1em 0.5em;
    }


    .coverage {
        display: flex;
        grid-template-columns: nset;
        grid-template-rows: unset;
        grid-auto-flow: unset;
        flex-direction: column;
    }

    .coverage p {
        padding: 1em;
    }

    .world-img img {
        width: 100%;
        height: auto;
    }

    .cvg {
        padding: 1em;
        /* width: 50vw; */
    }

    .vis-mis {
        flex-direction: column;
        padding: 1em;
    }

    .s-d-cont {
        width: 80%;
    }

    .s-d-cont ul {
        flex-direction: column;
        margin-left: 2em;
    }


    .s-d-cont ul li {
        padding: 0.5em;
    }

    .capacity {
        padding: 1em;
    }

    .capacity p:first-child {
        width: unset;
        padding: 1em;
    }

    .partner {
        flex-direction: column;
    }

    .partner img {
        width: 100%;
    }

    /* ****mobile scroll bar visible**** */
    /* !important is needed sometimes */
    ::-webkit-scrollbar {
        width: 12px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        -webkit-border-radius: 10px !important;
        border-radius: 10px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px !important;
        border-radius: 10px !important;
        background: #41617D !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;

    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: #41617D !important;
    }
}