footer {
    /* font-size: smaller; */
    letter-spacing: unset;
    font-size: 14px;
}

.footer_about {
    flex: calc(4 / 12);
}

.footer_about p {
    padding-right: 1em;
    letter-spacing: 1.5px;
    text-align: justify;
}

.footer_address {
    flex: calc(3 / 12);
}

/* .sm-wrap {
    flex: calc(2 / 12);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
} */

.footer_social {
    flex: calc(2 / 12);
}

.footer_nav {
    flex: calc(2 / 12);
}

footer h3 {
    font-size: 16px;
    font-weight: 600;
}

footer .nav_links {
    display: flex;
    flex-direction: column;
}

footer a {
    text-decoration: none;
    color: #fff;
    font-family: var(--font-cont);
}

footer a:hover,
footer a:focus {
    color: var(--color-accent-50);
}

footer.container .row {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
}

.social-links a {
    display: flex;
    align-items: center;
    gap: 0.5em
}

@media screen and (max-width: 1150px) {
    footer .row>div {
        margin: 1em auto;
        /* margin-left: 2em; */
    }

    footer.container .row {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer_about p {
        padding-right: unset;
    }
}